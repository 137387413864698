import React from 'react';
import Layout from '../components/Layout';
import CleanList from './../components/CleanList';

// Import styling components
import styled from 'styled-components';

// Styled components
const AboutContainer = styled.article`
  margin: auto;
  max-width: var(--line-length);
`;

const IntroContainer = styled.article`
  font-size: calc(20px + 0.25vw);
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  &::after {
    background: var(--trans-black);
    content: "";
    display: block;
    height: 2px;
    width: 100%;
  }
`;

const OutroText = styled.p`
  font-weight: bold;
  margin-top: 5rem;
  text-align: center;
`;

const SkillsAndInterests = styled.div`
  margin-bottom: 2rem;
  padding-bottom: 3rem;
  display: flex;
          flex-wrap: wrap;

  & > section { width: 50%; }

  @media (max-width: 620px) {
          & > section { width: 100%;}
  }
`;

const ResumeLink = styled.a`
  display: block;
  margin: 2rem auto;
  width: 100%;
`;

// Stateless functional components
const Intro = () => (
  <IntroContainer>
    <section>
      <h1>About</h1>

      <p>Brandon Ubben is a web developer based out of Los Angeles, CA. </p>

      <p>Graduate of California State University, Northridge with a B.S. in Computer Information Technology, car and music nerd, and fixed-gear bicycle enthusiast.</p>

      <ResumeLink className="link" href="https://images.ubben.co/resume.pdf" download target="_blank" rel="noopener noreferrer">Brandon Ubben's resume is available for viewing/download.</ResumeLink>
    </section>
  </IntroContainer>
);

const Education = () => (
  <section>
    <h3>Education</h3>
    <p>Bachelor of Science &mdash; major in Computer Information Technology, minor in Art with a focus on Web/Graphic Design from California State University, Northridge. Graduated May, 2018.</p>
  </section>
);

const Experience = () => (
  <section>
    <h3>Experience</h3>
    <CleanList>
      <li>
        <strong>Trucking Unlimited, <em>Web Engineer</em> &mdash; Since Feb. 2018</strong>
        <p>Full-stack development, IT problem-solving.</p>
      </li>
      <li>
        <strong>ACTA MoreToyGuns.com, <em>E- Commerce Manager</em> &mdash; Oct. 2013 to Jan. 2018</strong>
        <p>E-commerce platform management, order processing and fulfillment.</p>
      </li>
    </CleanList>
  </section>
);

const SelectedSkills = () => (
  <section>
    <h4>Selected Skills:</h4>
    <CleanList>
      <li>HTML</li>
      <li>CSS/SCSS</li>
      <li>JavaScript</li>
      <li>PHP</li>
      <li>Linux</li>
      <li>Node.js</li>
    </CleanList>
  </section>
);

const Interests = () => (
  <section>
    <h4>Interests:</h4>
    <CleanList>
      <li>Web Development</li>
      <li>Linux/OSX/Open-source</li>
      <li>Live Music</li>
      <li>Cool Cars</li>
      <li>Great Vegan food</li>
      <li>Mechanical keyboards</li>
    </CleanList>
  </section>
);

export default () => (
  <Layout pageTitle="About the Developer" view="/about">
    <AboutContainer>
      <Intro />
      <Education />
      <Experience />
      <SkillsAndInterests>
        <SelectedSkills />
        <Interests />
      </SkillsAndInterests>
    </AboutContainer>
  </Layout>
);
